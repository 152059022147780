import { createSlice } from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { ContractAddresses, useTokenContract } from '../web3/contracts/contracts';
import { useInvestedAmount } from '../web3/presaleotc';
import { useAccount } from './account';
import { useUserPoolShare, useInvestorFee, useUserPendingRewards, useInvestorPoolAmount, useMinRewardBalance, useRewardManager } from "../web3/rewardmanager";

export const investorRewardsSlice = createSlice({
    name: 'investorRewards',
    initialState: {
        pendingRewards: 0,
        hpayBalance: 0,
        presaleHpayAmount: 0,
        claimFee: 0,
        poolShare: 0,
        rewardEligible: 'notEligible'
    },
    reducers: {
        setPendingRewards: (state, action) => {
            state.pendingRewards = action.payload;
        },
        setClaimFee: (state, action) => {
            state.claimFee = action.payload;
        },
        setPoolShare: (state, action) => {
            state.poolShare = action.payload;
        },
        setRewardEligible: (state, action) => {
            state.rewardEligible = action.payload;
        },
        setHpayBalance: (state, action) => {
            state.hpayBalance = action.payload;
        },
        setPresaleHpayAmount: (state, action) => {
            state.presaleHpayAmount = action.payload;
        }
    },
});

export const { setPendingRewards, setClaimFee, setPoolShare, setAccount, setRewardEligible, setHpayBalance, setPresaleHpayAmount } = investorRewardsSlice.actions;

export const useInvestorRewardStatus = () => {
    const account = useAccount();
    const [status, refresh] = useRefreshRewardsEligibleStatus();
    const selector = useSelector(state => ({
        rewardEligible: state.investorRewards.rewardEligible, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);

    return selector;
};


export const useInvestorClaimFee = () => {
    const account = useAccount();
    const [status, refresh] = useRefreshInvestorFee();
    const selector = useSelector(state => ({
        claimFee: state.investorRewards.claimFee, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);

    return selector;
};


export const useInvestorPoolShare = () => {
    const account = useAccount();
    const { poolShareState, refreshPoolShare } = useRefreshPoolShare();
    const selector = useSelector(state => ({
        poolShare: state.investorRewards.poolShare, poolShareState
    }));

    useEffect(() => {
        refreshPoolShare(account);
    }, [refreshPoolShare, account]);

    return selector;
};

export const useInvestorPendingRewards = () => {
    const account = useAccount();

    const [status, refresh] = useRefreshPendingRewards();
    const selector = useSelector(state => ({
        pendingRewards: state.investorRewards.pendingRewards, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);

    return selector;
};


export const useAccountInvestment = () => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserInvestment();

    const selector = useSelector(state => ({
        presaleHpayAmount: state.investorRewards.presaleHpayAmount, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);

    return selector;
};

export const useInvestorHpayBalance = () => {
    const account = useAccount();
    const [status, refresh] = useRefreshHPayBalance();

    const selector = useSelector(state => ({
        hpayBalance: state.investorRewards.hpayBalance, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);

    return selector;
};

// This is an hook action which constructs and wraps an async action to produces `state` for us.
export const useRefreshPoolShare = () => {
    const dispatch = useDispatch();
    const fetchPoolShare = useUserPoolShare();

    const [poolShareState, refreshPoolShare] = useAsyncFn(async (address) => {
        const poolShare = await fetchPoolShare(address);
        dispatch(setPoolShare(poolShare));
    }, [dispatch, fetchPoolShare]);

    return {
        poolShareState,
        refreshPoolShare,
    };
};

export const useRefreshInvestorFee = () => {
    const dispatch = useDispatch();
    const fetchInvestorFee = useInvestorFee();

    return useAsyncFn(async (address) => {
        const fee = await fetchInvestorFee(address);
        dispatch(setClaimFee(fee));
    }, [dispatch, fetchInvestorFee]);
};

export const useRefreshPendingRewards = () => {
    const dispatch = useDispatch();
    const fetchPendingRewards = useUserPendingRewards();

    return useAsyncFn(async (address) => {
        const rewards = await fetchPendingRewards(address);
        dispatch(setPendingRewards(rewards));
    }, [dispatch, fetchPendingRewards]);
};

export const useRefreshUserInvestment = () => {
    const dispatch = useDispatch();
    const fectchAmount = useInvestedAmount();

    return useAsyncFn(async (address) => {
        const balance = await fectchAmount(address);
        dispatch(setPresaleHpayAmount(balance));
    }, [dispatch, fectchAmount]);
};

export const useRefreshHPayBalance = () => {
    const dispatch = useDispatch();
    const { balanceOf } = useTokenContract(ContractAddresses.HPAY);

    return useAsyncFn(async (address) => {
        const balance = await balanceOf(address);
        dispatch(setHpayBalance(balance));
    }, [dispatch, balanceOf]);
};

export const useRefreshRewardsEligibleStatus = () => {
    const dispatch = useDispatch();
    const fetchPendingRewards = useUserPendingRewards();
    const fetchPoolAmount = useInvestorPoolAmount();
    const { balanceOf } = useTokenContract(ContractAddresses.HPAY);
    const fetchMinRewardsBalance = useMinRewardBalance();

    return useAsyncFn(async (address) => {
        const userBalance = await balanceOf(address);
        const pendingRewards = await fetchPendingRewards(address);
        const poolAmount = await fetchPoolAmount(address);
        const minRewardBalanceThreshHold = await fetchMinRewardsBalance();

        if (+pendingRewards > 0 || poolAmount >= minRewardBalanceThreshHold) {
            dispatch(setRewardEligible('eligible'));
            return;
        }
        const status = userBalance > minRewardBalanceThreshHold ? "notActive" : "notEligible";
        dispatch(setRewardEligible(status));
    }, [dispatch, balanceOf, setRewardEligible, fetchPoolAmount, fetchPendingRewards, fetchMinRewardsBalance]);
};

export const useRefreshBountyValue = () => {
    const dispatch = useDispatch();
    const fetchPendingRewards = useUserPendingRewards();
    const fetchPoolAmount = useInvestorPoolAmount();
    const { balanceOf } = useTokenContract(ContractAddresses.HPAY);
    const fetchMinRewardsBalance = useMinRewardBalance();

    return useAsyncFn(async (address) => {
        const pendingRewards = await fetchPendingRewards(address);
        const poolAmount = await fetchPoolAmount(address);
        const minRewardBalanceThreshHold = await fetchMinRewardsBalance();
        const userBalance = await balanceOf(address);

        if (+pendingRewards > 10 || poolAmount >= minRewardBalanceThreshHold) {
            dispatch(setRewardEligible('eligible'));
            return;
        }

        const status = userBalance > minRewardBalanceThreshHold ? "notActive" : "notEligible";
        dispatch(setRewardEligible(status));
    }, [dispatch, balanceOf, setRewardEligible, fetchPoolAmount, fetchPendingRewards, fetchMinRewardsBalance]);
};


export const useRewardActions = () => {
    const [, refreshRewardStatus] = useRefreshRewardsEligibleStatus();
    const [, refreshPendingRewards] = useRefreshPendingRewards();
    const { refreshPoolShare } = useRefreshPoolShare();

    const { activate, claim } = useRewardManager(window.web3);

    const activateReward = useCallback(async (account) => {
        return activate(account).then(async (result) => {
            await Promise.all([
                refreshPendingRewards(account),
                refreshPoolShare(account),
                refreshRewardStatus(account)
            ]);
            return result;
        });
    }, [refreshRewardStatus, refreshPendingRewards, activate, refreshPoolShare]);

    const claimReward = useCallback(async (account) => {
        return claim(account).then(async (result) => {
            await refreshRewardStatus(account);
            await refreshPendingRewards(account);
            return result;
        });
    }, [refreshRewardStatus, refreshPendingRewards, claim]);

    return { activateReward, claimReward };
};


export default investorRewardsSlice.reducer;