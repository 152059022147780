import { useCallback } from "react";
import HedgeCoinStaking from "hpay/contracts/HedgeCoinStaking.json";
import { useContractWithProvider } from "./contracts/contracts";
import BigNumber from 'bignumber.js';
import { BLOCKS_PER_YEAR } from "../utils/utils";
import { __WEB_3__ } from "./contracts/contracts";


export const useStakingContract = (provider = __WEB_3__) => {
    const contract = useContractWithProvider(HedgeCoinStaking.address, HedgeCoinStaking.abi, provider);

    const pendingStakeReward = useCallback(async (account) => {

        if (!contract) {
            return;
        }
        const amount = await contract.methods.earned(account).call();
        return amount / 1e18;
    }, [contract]);

    const totalStakedAmount = useCallback(async () => {
        if (!contract) {
            return 0;
        }
        const amount = await contract.methods.totalSupply().call();
        return amount / 1e18;
    }, [contract]);

    const getApr = useCallback(async () => {

        if (!contract) {
            return 0;
        }

        const totalStaked = await totalStakedAmount();
        const rewardsPerBlock = await contract.methods.rewardRate().call();

        const totalRewardPricePerYear = new BigNumber(rewardsPerBlock).times(BLOCKS_PER_YEAR);
        const totalStakingTokenInPool = new BigNumber(Math.max(10, totalStaked));
        const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);

        let result = apr.isNaN() || !apr.isFinite() ? null : apr.toNumber();

        if (result !== null) {
            result = Math.min(result, 20000);
        }
        return result;
    }, [contract, totalStakedAmount]);

    const getApy = useCallback(async () => {

        const apr = await getApr();
        if (apr === 0) {
            return 0;
        }
        const daily = 365;

        let apy = (1 + (apr/100) / daily)**daily - 1;
        return apy * 100;

    }, [getApr]);

    const stakedAmount = useCallback(async (account) => {

        if (!contract) {
            return;
        }

        const amount = await contract.methods.stakedAmount(account).call();
        return amount / 1e18;
    }, [contract]);

    const stake = useCallback(async (account, amount) => {
        if (!contract) {
            return;
        }

        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        const wei = window.web3.utils.toWei(amount + '');
        await contract.methods.stake(wei).estimateGas(args);
        return contract.methods.stake(wei).send(args);
    }, [contract]);

    const unstake = useCallback(async (account, amount) => {
        if (!contract) {
            return;
        }

        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        const wei = window.web3.utils.toWei(amount + '');
        await contract.methods.withdraw(wei).estimateGas(args);
        return contract.methods.withdraw(wei).send(args);
    }, [contract]);

    const harvest = useCallback(async (account) => {

        if (!contract) {
            return;
        }

        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await contract.methods.getReward().estimateGas(args);
        return contract.methods.getReward().send(args);
    }, [contract]);

    const compound = useCallback(async (account) => {

        if (!contract) {
            return;
        }

        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await contract.methods.compound().estimateGas(args);
        return contract.methods.compound().send(args);
    }, [contract]);

    return {
        stake, unstake, harvest, stakedAmount, totalStakedAmount, pendingStakeReward, getApr, getApy, compound
    };
};
