import { JsonRpcProvider } from "@ethersproject/providers";
import {
    ChainId, Fetcher, Token, TokenAmount, Trade
} from "@pancakeswap/sdk";
import { useParams } from "@reach/router";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from 'react';
import { BSC_URL } from "../web3/contracts/contracts";
import Idos from "hpay/content/Idos.json";
import Vaults from "hpay/content/Vaults.json";

const provider = new JsonRpcProvider(BSC_URL);

export const UndefinedToZero = (prop) => (obj) => (obj && obj[prop]) || 0;

export const roundToEight = (num) => {
    return +(Math.floor(num + "e+8") + "e-8");
};

export const roundToSix = (num) => {
    return +(Math.floor(num + "e+6") + "e-6");
};
export const roundToFour = (num) => {
    return +(Math.floor(num + "e+4") + "e-4");
};
export const roundToTwo = (num) => {
    return +(Math.floor(num + "e+2") + "e-2");
};
export const round = (num) => {
    return +(Math.floor(num + "e+0") + "e-0");
};

export const TOKEN_DECIMALS = 8;
export const BSC_BLOCK_TIME = 3;
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365);


export const usePersistedState = (key, defaultValue) => {
    const [state, setState] = React.useState(() => {
        const persistedState = localStorage.getItem(key);
        return persistedState ? JSON.parse(persistedState) : defaultValue;
    });
    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(state));
    }, [state, key]);
    return [state, setState];
};

export const computeTrade = async (amountIn, path) => {
    const pairs = [];

    const tokens = path.map(item => new Token(
        ChainId.MAINNET,
        item,
        18
    ));
    for (let index = 1; index < tokens.length; index++) {
        const tokenA = tokens[index - 1];
        const tokenB = tokens[index];
        const pair = await Fetcher.fetchPairData(tokenA, tokenB, provider);
        pairs.push(pair);
    }

    const out = new Token(
        ChainId.MAINNET,
        path[0],
        18
    );

    const trade = Trade.bestTradeExactIn(
        pairs,
        new TokenAmount(out, window.web3.utils.toWei('' + amountIn, 'ether')),
        tokens.pop(),
        { maxHops: 2 }
    ).pop();
    return trade;
};

export const usePair = (tokenA, tokenB) => {
    const [pair, sePair] = useState(null);
    useEffect(() => {

        const A = new Token(
            ChainId.MAINNET,
            tokenA,
            18
        );

        const B = new Token(
            ChainId.MAINNET,
            tokenB,
            18
        );
        Fetcher.fetchPairData(A, B, provider).then(sePair);
    }, [tokenA, tokenB]);

    return pair;
};


export const useIdoAddress = () => {
    const params = useParams();
    const [address, setAddress] = useState();

    useEffect(() => {
        setAddress(Idos[params.idoAddress].address);
    }, [params, setAddress]);

    return address;
};


export const useVaultAddress = () => {
    const params = useParams();
    const [address, setAddress] = useState();

    useEffect(() => {
        setAddress(Vaults[params.vaultAddress].address);
    }, [params, setAddress]);

    return address;
};
