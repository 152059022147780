
import { BLOCKCHAIN_NODE_URL, RUNTIME_ENV } from "gatsby-env-variables";
import Exchange from "hpay/contracts/Exchange.json";
import Fund from "hpay/contracts/Fund.json";
import HedgeCoin from "hpay/contracts/HedgeCoin.json";
import Staking from "hpay/contracts/HedgeCoinStaking.json";
import Transfer from "hpay/contracts/Transfer.json";
import RewardStash from "hpay/contracts/RewardStash.json";
import { useCallback, useEffect, useState } from "react";
import * as Web3 from 'web3/dist/web3.min.js';
import { ContractFactoryHof } from "./contract.factory";

export let TARGET_NETWORK;
export let BSC_URL = BLOCKCHAIN_NODE_URL;
export let ContractAddresses;
export let Tokens;

console.log(BSC_URL);
// Main net
if (RUNTIME_ENV === 'testnet') {
    TARGET_NETWORK = '97';
    ContractAddresses = {
        BUSD: "0x8301f2213c0eed49a7e28ae4c3e91722919b8b47",
        BNB: "0x2b8ff854c5e16cf35b9a792390cc3a2a60ec9ba2",
        HPAY: HedgeCoin.address,
        STASH: '0xf7c1dfCd559C3BF0E6Eb702deF3ef30811b91658',
        EXCHANGE: Exchange.address,
        TRANSFER: Transfer.address

    };
    Tokens = {
        BUSD: {
            address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
            decimals: 18,
            symbol: 'BUSD'
        },
        BNB: {
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            decimals: 18,
            symbol: 'BNB'
        },
        HPAY: {
            address: HedgeCoin.address,
            decimals: 18,
            symbol: 'HPAY'
        }
    };
} else {
    TARGET_NETWORK = '56';
    Tokens = {
        BUSD: {
            address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            decimals: 18,
            symbol: 'BUSD'
        },
        BNB: {
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            decimals: 18,
            symbol: 'BNB'
        },
        HPAY: {
            address: HedgeCoin.address,
            decimals: 18,
            symbol: 'HPAY'
        }
    };
    ContractAddresses = {
        BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        BNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        HPAY: HedgeCoin.address,
        STASH: RewardStash.address,
        FUND: Fund.address,
        STAKING: Staking.address,
        EXCHANGE: Exchange.address,
        TRANSFER: Transfer.address
    };
}

export const __WEB_3__ = new Web3(BSC_URL);
export const ContractFactory = ContractFactoryHof(__WEB_3__);
window.web3 = __WEB_3__;

const CONTRACTS = {
    _BUSDContract_instance: null,
    _BNBContract_instance: null,
    _BnbBusdContract_instance: null,
    _HegeToken_instance: null

};

export const getContract = async (contract, address, abi) => {
    if (CONTRACTS[contract]) {
        return CONTRACTS[contract];
    }

    CONTRACTS[contract] = await ContractFactory.create(
        abi,
        address
    );
    return CONTRACTS[contract];
};

export const BUSDContract = async () => {
    return getContract('_BUSDContract_instance', ContractAddresses.BUSD, HedgeCoin.abi);
};
export const BNBContract = async () => {
    return getContract('_BNBContract_instance', ContractAddresses.BNB, HedgeCoin.abi);
};
export const HegeToken = async () => {
    return getContract('_HegeToken_instance', ContractAddresses.HPAY, HedgeCoin.abi);
};

export const ContractInstances = {
    BUSD: BUSDContract,
    BNB: BNBContract,
    HPAY: HegeToken,
};


export const useContractWithProvider = (address, abi, provider) => {
    const [contract, setContract] = useState(null);
    const getContract = useCallback(async () => {
        if (!address || !provider) {
            return;
        }

        const factory = ContractFactoryHof(provider);
        const _contract = await factory.create(abi, address);
        setContract(_contract);
    }, [abi, address, provider, setContract]);

    useEffect(() => getContract(), [getContract]);

    return contract;
};

export const useContract = (address, abi) => {
    const [contract, setContract] = useState(null);

    const contractFn = useCallback(async () => {
        setContract(await getContract(address, address, abi));
    }, [address, abi]);
    useEffect(() => contractFn(), [contractFn]);
    return contract;
};

export const useTokenContract = (address, provider = __WEB_3__) => {
    const contract = useContractWithProvider(address, HedgeCoin.abi, provider);
    const [supply, setSupply] = useState(0);
    const [decimals, setDecimals] = useState(0);

    const balanceOf = useCallback(async (account) => {
        if (!contract || !account) {
            return 0;
        }

        let balance = await contract.methods.balanceOf(account).call();
        let decimals = await contract.methods.decimals().call();
        return +(balance / 10 ** decimals).toFixed(decimals);
    }, [contract]);

    useEffect(() => {
        if (!contract) {
            return;
        }
        const init = async () => {
            let decimals = await contract.methods.decimals().call();
            let supply = await contract.methods.totalSupply().call();
            supply = +(supply / 10 ** decimals).toFixed();
            setSupply(supply);
            setDecimals(decimals);
        };
        init();

    }, [contract]);

    return { contract, balanceOf, supply, decimals };
};
