import { createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useFund } from "../web3/fund";

export const fundInfoSlice = createSlice({
    name: 'fundInfo',
    initialState: {
        apr: 0,
        bountyValue: 0
    },
    reducers: {
        setApr: (state, action) => {
            state.apr = action.payload;
        },
        setBountyValue: (state, action) => {
            state.bountyValue = action.payload;
        }
    },
});

export const { setApr, setBountyValue} = fundInfoSlice.actions;

export const useFundApr = () => {
    const [ status, refresh ] = useRefreshFundApr();
    const selector = useSelector(state => ({
        apr: state.fundInfo.apr, status
    }));

    useEffect(() => {
        refresh();
    }, [refresh]);

    return selector;
};

// This is an hook action which constructs and wraps an async action to produces `state` for us.
export const useRefreshFundApr = () => {
    const dispatch = useDispatch();
    const { fundApr } = useFund();

    return useAsyncFn(async () => {
        const apr = await fundApr();
        dispatch(setApr(apr));
    }, [dispatch, fundApr]);

};

export const useBountyValue = () => {
    const [ status, refresh ] = useRefreshFundApr();
    const selector = useSelector(state => ({
        apr: state.fundInfo.apr, status
    }));

    useEffect(() => {
        refresh();
    }, [refresh]);

    return selector;
};

// This is an hook action which constructs and wraps an async action to produces `state` for us.
export const useRefreshuseBountyValue = () => {
    const dispatch = useDispatch();
    const { pendingProfit } = useFund();

    return useAsyncFn(async () => {
        const value = await pendingProfit();
        dispatch(setBountyValue(value));
    }, [dispatch, pendingProfit]);

};


export default fundInfoSlice.reducer;