import { configureStore } from '@reduxjs/toolkit';
import presaleReducer from './presale';
import exchangeReducer from './exchange';
import investorRewardsReducer from './investor-info';
import accountReducer from './account';
import fundInforeducer from './fund-info';
import bountyReducer from './bounty';
import stakingReducer from './staking';
import idoReducer from './idos';
import proxyIdoReducer from './proxy-funded-ido';
import flashStakingReducer from './flash-staking';
import timeLockStakingReducer from './timelock-staking';
import simpleStakingReducer from './simple-staking';

import vaultsReducer from './vaults';

const createStore = () => configureStore({
  reducer: {
    presale: presaleReducer,
    exchange: exchangeReducer,
    investorRewards: investorRewardsReducer,
    account: accountReducer,
    fundInfo: fundInforeducer,
    bountyInfo: bountyReducer,
    stakingInfo: stakingReducer,
    idos: idoReducer,
    proxyIdo: proxyIdoReducer,
    vaults: vaultsReducer,
    flashStaking: flashStakingReducer,
    timeLockStaking: timeLockStakingReducer,
    simpleStaking: simpleStakingReducer

  },
  devTools: process.env.NODE_ENV !== 'production'
});

export default createStore;