import { useCallback, useMemo } from "react";
import Fund from "hpay/contracts/Fund.json";
import FundWorker from "hpay/contracts/FundWorker.json";

import { useContractWithProvider, __WEB_3__ } from "./contracts/contracts";


const calculateApr = (prevValue, currValue, prevTime, currTime, capitalValue) => {
    const profitDiff = currValue - prevValue;

    const timeDiff = (currTime - prevTime);

    const profitIncreasePercentPerSec = profitDiff / timeDiff;

    const profitIncreasePercent = (profitIncreasePercentPerSec * 100) / capitalValue;

    let apr = profitIncreasePercent * 60 * 60 * 24 * 365;

    apr = isNaN(apr) ? 0 : apr;
    apr = !isFinite(apr) ? 0 : apr;

    return apr;
};

export const useFundWorker = () => {
    const contract = useContractWithProvider(FundWorker.address, FundWorker.abi, window.web3);

    const bountyRewards = useCallback(async () => {
        if (!contract) {
            return;
        }
        const { currentProfits, bounty } = await contract.methods.collectableProfits().call();
        return { currentProfits: currentProfits / 1e18, bounty: bounty / 1e18 };
    }, [contract]);

    const bountyBonus = useCallback(async () => {
        if (!contract) {
            return;
        }

        const [available, amount, nextBonus] = await contract.methods.bonusStats().call();


        return {
            available: +available > 0,
            amount: amount / 1e18,
            nextBonus: nextBonus * 1000
        };
    }, [contract]);


    const stashProfits = useCallback(async (account) => {
        if (!contract) {
            return;
        }

        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await contract.methods.collectProfits().estimateGas(args);
        return contract.methods.collectProfits().send(args);
    }, [contract]);


    return {
        bountyRewards,
        stashProfits,
        bountyBonus
    };
};

export const useFund = () => {
    const fundContract = useContractWithProvider(Fund.address, Fund.abi, __WEB_3__);
    const pendingProfit = useCallback(async () => {
        if (!fundContract) {
            return 0;
        }

        const pendingProfits = await fundContract.methods.pendingProfits().call();
        return pendingProfits / 1e18;

    }, [fundContract]);

    const fundApr = useCallback(async () => {

        if (!fundContract) {
            return 0;
        }

        const { time0, total0, time1, total1 } = await fundContract.methods.rewardSnapShot().call();

        const currentTotalRewards = await fundContract.methods.totalGenerateRewardsWithProfits().call();
        const totalCapitalValue = await fundContract.methods.totalCapitalValue().call();

        const block = await __WEB_3__.eth.getBlock("latest");
        if (+time1 === 0 && +time0 === 0) {
            return 0;
        }

        const apr1 = +time0 !== 0 ? calculateApr(+(total0 / 1e18), +(currentTotalRewards / 1e18), +time0, +block.timestamp, totalCapitalValue / 1e18) : Number.MAX_VALUE;
        const apr2 = +time1 !== 0 ? calculateApr(+(total1 / 1e18), +(currentTotalRewards / 1e18), +time1, +block.timestamp, totalCapitalValue / 1e18) : Number.MAX_VALUE;

        return Math.max(0, Math.max(apr2, apr1));
    }, [fundContract]);

    return useMemo(() => ({
        fundContract, fundApr, pendingProfit
    }), [fundContract, fundApr, pendingProfit]);
};
