import { useCallback } from "react";
import RewardManager from "hpay/contracts/RewardManager.json";
import { useContract, useContractWithProvider } from "./contracts/contracts";
import { __WEB_3__ } from "./contracts/contracts";

export const useRewardManager = (provider = __WEB_3__) => {
    const rewardManagerContract = useContractWithProvider(RewardManager.address, RewardManager.abi, provider);
    const fetchMinRewardsBalance = useCallback(async () => {
        if (!rewardManagerContract) {
            return;
        }

        const result = await rewardManagerContract.methods.minRewardBalance().call();
        return (result / 1e18);
    }, [rewardManagerContract]);

    const claim = useCallback(async (account) => {
        if (!rewardManagerContract) {
            return;
        }

        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await rewardManagerContract.methods.claimReward(0).estimateGas(args);
        return rewardManagerContract.methods.claimReward(0).send(args);
    }, [rewardManagerContract]);

    const activate = useCallback(async (account) => {
        if (!rewardManagerContract) {
            return;
        }


        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await rewardManagerContract.methods.activateRewards(account).estimateGas(args);
        return rewardManagerContract.methods.activateRewards(account).send(args);
    }, [rewardManagerContract]);

    return { claim, activate, fetchMinRewardsBalance };
};


export const useMinRewardBalance = () => {
    const rewardManagerContract = useContract(RewardManager.address, RewardManager.abi);
    const fetchMinRewardsBalance = useCallback(async () => {
        if (!rewardManagerContract) {
            return;
        }

        const result = await rewardManagerContract.methods.minRewardBalance().call();
        return (result / 1e18);
    }, [rewardManagerContract]);

    return fetchMinRewardsBalance;
};

export const useIsRewardEligible = () => {
    const rewardManagerContract = useContract(RewardManager.address, RewardManager.abi);
    const fetchData = useCallback(async (address) => {
        if (!rewardManagerContract) {
            return false;
        }
        return await rewardManagerContract.methods.rewardEligible(address).call();
    }, [rewardManagerContract]);

    return fetchData;
};

export const useUserPendingRewards = () => {
    const rewardManagerContract = useContract(RewardManager.address, RewardManager.abi);

    const fetchPendingRewards = useCallback(async (address) => {
        if (!rewardManagerContract) {
            return 0;
        }

        const profit = await rewardManagerContract.methods.pendingReward(address).call();
        return profit / 1e18;
    }, [rewardManagerContract]);

    return fetchPendingRewards;
};

export const useInvestorPoolAmount = () => {
    const rewardManagerContract = useContract(RewardManager.address, RewardManager.abi);

    const fetchPoolAmount = useCallback(async (address) => {
        if (!rewardManagerContract) {
            return 0;
        }

        const { amount } = await rewardManagerContract.methods.investorInfo(address).call();
        return amount / 1e18;
    }, [rewardManagerContract]);

    return fetchPoolAmount;
};

export const useInvestorFee = () => {
    const rewardManagerContract = useContract(RewardManager.address, RewardManager.abi);

    const fetchInvestorFee = useCallback(async (address) => {
        if (!rewardManagerContract) {
            return 0;
        }

        const fee = await rewardManagerContract.methods.fee(address).call();
        return fee;
    }, [rewardManagerContract]);

    return fetchInvestorFee;
};

export const useUserPoolShare = () => {
    const rewardManagerContract = useContract(RewardManager.address, RewardManager.abi);

    const fetchPoolShare = useCallback(async (address) => {
        if (!rewardManagerContract) {
            return 0;
        }
        const { amount } = await rewardManagerContract.methods.investorInfo(address).call();
        const totalPoolSize = await rewardManagerContract.methods.totalDeposited().call();
        const poolShore = (amount * 100 / totalPoolSize);
        return poolShore;
    }, [rewardManagerContract]);

    return fetchPoolShare;
};