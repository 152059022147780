import { createSlice } from '@reduxjs/toolkit';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useStakingContract } from '../web3/staking';
import { useAccount } from './account';
import { useRefreshHPayBalance, useRefreshPendingRewards, useRefreshPoolShare, useRefreshRewardsEligibleStatus } from './investor-info';

export const stakingInfo = createSlice({
    name: 'stakingInfo',
    initialState: {
        stakedAmount: 0,
        totalStakedAmount: 0,
        pendingReward: 0
    },
    reducers: {
        setStakedAmount: (state, action) => {
            state.stakedAmount = action.payload;
        },
        setPendingReward: (state, action) => {
            state.pendingReward = action.payload;
        },
        setTotalStakedAmount: (state, action) => {
            state.totalStakedAmount = action.payload;
        }
    },
});

export const { setTotalStakedAmount, setStakedAmount, setPendingReward } = stakingInfo.actions;

export const useTotalStakedAmount = () => {
    const [status, refresh] = useRefreshTotalStakedAmount();

    const selector = useSelector(state => ({
        totalStakedAmount: state.stakingInfo.totalStakedAmount, status
    }));

    useEffect(() => {
        refresh();
    }, [refresh]);
    return selector;
};

export const useStakingApr = () => {
    const [apr, setApr] = useState(0);
    const [apy, setApy] = useState(0);

    const { getApr, getApy} = useStakingContract();

    useEffect(() => {
        getApr().then(setApr);
        getApy().then(setApy);

    }, [getApr, getApy]);
    return { apr, apy };
};

export const useStakedAmount = () => {
    const account = useAccount();
    const [status, refresh] = useRefreshStakedAmount();

    const selector = useSelector(state => ({
        stakedAmount: state.stakingInfo.stakedAmount, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);

    return selector;
};

export const useStakingReward = () => {
    const account = useAccount();
    const [status, refresh] = useRefreshStakingReward();
    const selector = useSelector(state => ({
        pendingReward: state.stakingInfo.pendingReward, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);

    return selector;
};

export const useRefreshStakingReward = () => {
    const dispatch = useDispatch();
    const { pendingStakeReward } = useStakingContract(window.web3);

    return useAsyncFn(async (account) => {
        const amount = await pendingStakeReward(account);
        dispatch(setPendingReward(amount));
    }, [dispatch, pendingStakeReward, setPendingReward]);
};


export const useRefreshTotalStakedAmount = () => {
    const dispatch = useDispatch();
    const { totalStakedAmount } = useStakingContract();

    return useAsyncFn(async () => {
        const amount = await totalStakedAmount();
        dispatch(setTotalStakedAmount(amount));
    }, [dispatch, totalStakedAmount, setTotalStakedAmount]);
};

export const useRefreshStakedAmount = () => {
    const dispatch = useDispatch();
    const { stakedAmount } = useStakingContract();

    return useAsyncFn(async (account) => {
        const amount = await stakedAmount(account);
        dispatch(setStakedAmount(amount));
    }, [dispatch, stakedAmount, setStakedAmount]);
};

export const useStakingActions = () => {
    const account = useAccount();

    const { stake, unstake, harvest, compound} = useStakingContract(window.web3);
    const [, refreshStakedAmount] = useRefreshStakedAmount();
    const [, refreshStakingReward] = useRefreshStakingReward();
    const { refreshPoolShare } = useRefreshPoolShare();
    const [, refreshPendingRewards] = useRefreshPendingRewards();
    const [, refreshRewardsEligibleStatus] = useRefreshRewardsEligibleStatus();
    const [, refreshHPayBalance] = useRefreshHPayBalance();

    const refreshData = useCallback(async (account) => Promise.all([
        await refreshStakedAmount(account),
        await refreshStakingReward(account),
        await refreshPoolShare(account),
        await refreshPendingRewards(account),
        await refreshRewardsEligibleStatus(account),
        await refreshHPayBalance(account)

    ]), [refreshStakingReward, refreshHPayBalance, refreshStakedAmount, refreshPoolShare, refreshPendingRewards, refreshRewardsEligibleStatus]);

    const stakeTokens = useCallback(async (amount) => {
        return stake(account, amount).then(async (result) => {
            await refreshData(account);
            return result;
        });
    }, [stake, account, refreshData]);

    const unstakeTokens = useCallback(async (amount) => {
        return unstake(account, amount).then(async (result) => {
            await refreshData(account);

            return result;
        });
    }, [unstake, account, refreshData]);

    const harvestReward = useCallback(async () => {
        return harvest(account).then(async (result) => {
            await refreshData(account);

            return result;
        });
    }, [harvest, account, refreshData]);

    const compoundReward = useCallback(async () => {
        return compound(account).then(async (result) => {
            await refreshData(account);

            return result;
        });
    }, [compound, account, refreshData]);

    return { stakeTokens, unstakeTokens, harvestReward, compoundReward};
};


export default stakingInfo.reducer;