import { createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount, subscribeToAccountChange, subscribeToNeworkConnect } from '../web3/web3';

export const accountSlice = createSlice({
    name: 'account',
    initialState: {
        account: null,
        connected: false
    },
    reducers: {
        setAccount: (state, action) => {
            state.account = action.payload;
        },
        setConnected: (state, action) => {
            state.connected = action.payload;
        },
    },
});

export const { setAccount, setConnected } = accountSlice.actions;

export const useAccount = () => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account.account);
    const _connected = useSelector(state => state.account.connected);

    useEffect(() => {
        const initAccount = async () => {
            const _account = await getAccount();
            dispatch(setAccount(_account));
        };
        subscribeToAccountChange('account-slice', (account) => {
            dispatch(setAccount(account));
        });
        subscribeToNeworkConnect('account-slice', (connected) => {
            dispatch(setConnected(!!connected));
            if (!!connected) {
                initAccount();
            }

        });
    }, [dispatch]);
    return _connected && account;
};


export default accountSlice.reducer;