import { createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useExchangeCapsFetch, useHpayExchangePrice } from '../web3/exchange';

export const exchangeSlice = createSlice({
    name: 'exchange',
    initialState: {
        token1: null,
        token2: null,
        price: 0,
        minAmount: 0,
        maxAmount: 0
    },
    reducers: {
        setToken1: (state, action) => {
            state.token1 = action.payload;
        },
        setToken2: (state, action) => {
            state.token2 = action.payload;
        },
        setMinAmount: (state, action) => {
            state.minAmount = action.payload;
        },
        setMaxAmount: (state, action) => {
            state.maxAmount = action.payload;
        },
        setPrice: (state, action) => {
            state.price = action.payload || 0;
        },
    },
});


export const { setToken1, setToken2, setPrice, setMaxAmount, setMinAmount } = exchangeSlice.actions;

export const usePresaleCaps = () => {
    const [ refresh] = useRefreshCaps();

    const selector = useSelector(state => ({
        minAmount: state.exchange.minAmount,
        maxAmount: state.exchange.maxAmount
    }));

    useEffect(() => {
        refresh();
    }, [refresh]);

    return selector;
};

export const useExchangeRates = (token1, token2) => {
    const {  refreshRates } = useRefreshRates();

    const selector = useSelector(state => state.exchange.price);

    useEffect(() => {
        refreshRates(token1, token2);
    }, [refreshRates, token1, token2]);

    return [selector, () => refreshRates(token1, token2)];
};

export const useRefreshRates = () => {
    const dispatch = useDispatch();
    const fetchRates = useHpayExchangePrice();

    const [ratesState, refreshRates] = useAsyncFn(async (token1, token2) => {
        const rate = await fetchRates(token1, token2);
        dispatch(setPrice(rate));
    }, [dispatch, fetchRates]);

    return {
        ratesState,
        refreshRates,
    };
};


export const useRefreshCaps = () => {
    const dispatch = useDispatch();
    const fetchCaps = useExchangeCapsFetch();

    return useAsyncFn(async () => {
        const { minAmount, maxAmount } = await fetchCaps();

        dispatch(setMinAmount(minAmount));
        dispatch(setMaxAmount(maxAmount));
    }, [dispatch, fetchCaps, setMaxAmount, setMinAmount]);
};



export default exchangeSlice.reducer;