import { createSlice } from '@reduxjs/toolkit';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { ContractAddresses, useTokenContract } from '../web3/contracts/contracts';
import { useCurrentPhase, usePresaleCapitalFetch, usePresaleCapsFetch, usePresaleOtcContract, usePresalePhase, useTotalPhases } from '../web3/presaleotc';

export const presaleSlice = createSlice({
    name: 'presale',
    initialState: {
        supply: 0,
        currentPhase: 1,
        capital: 0,
        buyRate: 0,
        profit: 0,
        sellRate: 0,
        minBuy: 0,
        maxBuy: 0,
        hardCap: 0,
        phases: []
    },
    reducers: {
        setPhases: (state, action) => {
            state.phases = action.payload;
        },
        setSupply: (state, action) => {
            state.supply = action.payload;
        },
        setCapital: (state, action) => {
            state.capital = action.payload || 0;
        },
        setProfit: (state, action) => {
            state.profit = action.payload;
        },
        setMinBuy: (state, action) => {
            state.minBuy = action.payload;
        },
        setCurrentPhase: (state, action) => {
            state.currentPhase = action.payload;
        },
        setHardCap: (state, action) => {
            state.hardCap = action.payload;
        },
        setMaxBuy: (state, action) => {
            state.maxBuy = action.payload;
        },
        setRates: (state, action) => {
            state.buyRate = action.payload.buyRate;
            state.sellRate = action.payload.sellRate;
        },
    },
});


export const { setSupply, setCapital, setProfit, setRates, setHardCap, setMaxBuy, setMinBuy, setPhases } = presaleSlice.actions;

export const usePresaleCaps = () => {
    const [status] = useRefreshCaps();

    const selector = useSelector(state => ({
        minBuy: state.presale.minBuy,
        maxBuy: state.presale.maxBuy,
        hardCap: state.presale.hardCap,
        status
    }));

    // useEffect(() => {
    //     refresh();
    // }, [refresh]);

    return selector;
};


export const usePresalePhases = () => {
    const [status, refresh] = useRefreshPhases();

    const selector = useSelector(state => ({
        phases: state.presale.phases, status
    }));

    useEffect(() => {
        refresh();
    }, [refresh]);
    return selector;
};

export const useRefreshPhases = () => {
    const dispatch = useDispatch();
    const totalPhases = useTotalPhases();
    const fetchPhase = usePresalePhase();

    return useAsyncFn(async () => {
        const phases = [];
        const pNr = await totalPhases();

        for (let index = 0; index < pNr; index++) {
            const phase = await fetchPhase(index);
            if (index > 0 && phases[index - 1].status === 3) {
                phase.endTime = phases[index - 1].endTime;
                phase.status = 3;
            }
            phases.push(phase);
        }
        dispatch(setPhases(phases));
    }, [dispatch, totalPhases, fetchPhase]);
};

export const usePresalePhaseRate = () => {
    const { phases } = usePresalePhases();
    const fetchCurrentPhase = useCurrentPhase();
    return useCallback(async () => {

        const current = await fetchCurrentPhase();
        if (phases && phases.length && phases[current]) {
            return {
                buyRate: phases[current].rate,
                sellRate: phases[current].sellRate,
            };
        }

    }, [fetchCurrentPhase, phases]);
};

export const useCurrentPresalePhase = () => {
    const [current, setCurrent] = useState(1);
    const fetchCurrentPhase = useCurrentPhase();
    useEffect(() => {
        const fn = async () => {
            const current = await fetchCurrentPhase();
            setCurrent(current + 1);
        };
        fn();
    }, [fetchCurrentPhase, setCurrent]);

    return current;
};


export const usePresaleRates = () => {
    const { ratesState, refreshRates } = useRefreshRates();

    const selector = useSelector(state => ({
        buyRate: state.presale.buyRate, sellRate: state.presale.sellRate, ratesState
    }));

    useEffect(() => {
        refreshRates();
    }, [refreshRates]);

    return selector;
};

export const usePresaleSupply = () => {
    const { supplyState, refreshSupply } = useRefreshSupply();
    const selector = useSelector(state => ({
        supply: state.presale.supply, supplyState
    }));
    useEffect(() => {
        refreshSupply();
    }, [refreshSupply]);

    return selector;
};

export const usePresaleCapital = () => {
    const { capitalState, refreshCapital } = useRefreshCapital();

    const selector = useSelector(state => ({
        capital: state.presale.capital, capitalState
    }));

    useEffect(() => {
        refreshCapital();
    }, [refreshCapital]);

    return selector;
};



// This is an hook action which constructs and wraps an async action to produces `state` for us.
export const useRefreshSupply = () => {
    const dispatch = useDispatch();
    const presaleContract = usePresaleOtcContract();
    const { contract: tokenContract } = useTokenContract(ContractAddresses.HPAY);

    const [supplyState, refreshSupply] = useAsyncFn(async () => {
        if (!tokenContract || !presaleContract) {
            return;
        }
        const decimals = await tokenContract.methods.decimals().call();
        const _supply = await presaleContract.methods.saleSupply().call();

        dispatch(setSupply(_supply / 10 ** decimals));
    }, [dispatch, presaleContract, tokenContract]);

    return {
        supplyState,
        refreshSupply,
    };
};

export const useRefreshCapital = () => {
    const dispatch = useDispatch();
    const fetchCapital = usePresaleCapitalFetch();

    const [capitalState, refreshCapital] = useAsyncFn(async () => {
        const capital = await fetchCapital();
        dispatch(setCapital(capital));
    }, [dispatch, fetchCapital]);

    return {
        capitalState,
        refreshCapital,
    };
};


export const useRefreshRates = () => {
    const dispatch = useDispatch();
    const fetchRates = usePresalePhaseRate();

    const [ratesState, refreshRates] = useAsyncFn(async () => {
        const rates = await fetchRates();
        dispatch(setRates(rates));
    }, [dispatch, fetchRates]);

    return {
        ratesState,
        refreshRates,
    };
};



export const useRefreshCaps = () => {
    const dispatch = useDispatch();
    const fetchCaps = usePresaleCapsFetch();

    return useAsyncFn(async () => {
        const { minAmount, maxAmount, hardCap } = await fetchCaps();

        dispatch(setMinBuy(minAmount));
        dispatch(setMaxBuy(maxAmount));
        dispatch(setHardCap(hardCap));
    }, [dispatch, fetchCaps, setMinBuy, setMaxBuy, setHardCap]);
};



export default presaleSlice.reducer;