// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ido-js": () => import("./../../../src/pages/ido.js" /* webpackChunkName: "component---src-pages-ido-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invest-js": () => import("./../../../src/pages/invest.js" /* webpackChunkName: "component---src-pages-invest-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rewards-js": () => import("./../../../src/pages/rewards.js" /* webpackChunkName: "component---src-pages-rewards-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-transfer-js": () => import("./../../../src/pages/transfer.js" /* webpackChunkName: "component---src-pages-transfer-js" */),
  "component---src-pages-vaults-js": () => import("./../../../src/pages/vaults.js" /* webpackChunkName: "component---src-pages-vaults-js" */),
  "component---src-pages-wechat-js": () => import("./../../../src/pages/wechat.js" /* webpackChunkName: "component---src-pages-wechat-js" */)
}

