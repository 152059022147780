import { useCallback } from "react";
import { useRefreshIdo, useRefreshInvestmentAmount, useRefreshUserBalanceAmount, useRefreshWhilteListed } from "../state/proxy-funded-ido";
import { useContractWithProvider, __WEB_3__ } from "./contracts/contracts";
import ProxyFundedIdo from "hpay/contracts/ProxyFundedIdo";

import { getBlockTime } from "./web3";
import { ContractFactoryHof } from "./contracts/contract.factory";

export const useIdoContract = (address, provider = __WEB_3__) => {
    const contract = useContractWithProvider(address, ProxyFundedIdo.abi, provider);
    return contract;
};


export const useIdo = () => {
    const fetchIdo = useCallback(async (address) => {
        const idoContract = await ContractFactoryHof(__WEB_3__).create(ProxyFundedIdo.abi, address);

        if (!idoContract) {
            return;
        }

        const blockTime = await getBlockTime();
        const [rate, minBuy, maxBuy, hardCap, softCap, capitalRaised, startTime, endTime] = await idoContract.methods.stats().call();
        const ido = {
            minPurchase: minBuy / 1e18,
            maxPurchase: maxBuy / 1e18,
            capital: capitalRaised / 1e18,
            hardCap: hardCap / 1e18,
            rate: rate / 1e18,
            softCap: softCap / 1e18,
            startTime: startTime * 1000,
            endTime: endTime * 1000,
            idoContract: address,
        };

        if (ido.startTime > blockTime) {
            ido.status = 0; //started
        }

        if (ido.startTime <= blockTime && ido.endTime >= blockTime) {
            ido.status = 1; // running
            if (ido.capital >= ido.hardCap) {
                ido.status = 2; //ended
                ido.endTime = blockTime;
            }
        }

        if (ido.endTime < blockTime) {
            if (ido.capital >= ido.softCap) {
                ido.status = 2; // ended
            }

            if (ido.capital < ido.softCap) {
                ido.status = 3; //failed
            }
        }
        return ido;
    }, []);
    return fetchIdo;
};




export const useIsAdmin = (idoContractAddress) => {
    const idoContract = useIdoContract(idoContractAddress);

    const fetchCapital = useCallback(async (account) => {

        if (!idoContract) {
            return false;
        }

        const hash = window.web3.utils.keccak256('MANAGER_ROLE');
        const status = await idoContract.methods.hasRole(hash, account).call();

        return status;

    }, [idoContract]);

    return fetchCapital;
};

export const useIsWhiteListed = (idoAddress) => {
    const idoContract = useIdoContract(idoAddress);

    const fetchCapital = useCallback(async (account) => {
        if (!idoContract) {
            return false;
        }
        const openForAll = await idoContract.methods.openForAll().call();
        const status = await idoContract.methods.isWhitelisted(account).call();
        return status || openForAll;
    }, [idoContract]);

    return fetchCapital;
};

export const useInvestedAmount = (address) => {
    const idoContract = useIdoContract(address);

    const fetchCapital = useCallback(async (account) => {
        if (!idoContract) {
            return 0;
        }
        const amount = await idoContract.methods.investments(account).call();

        return amount / 1e18;

    }, [idoContract]);

    return fetchCapital;
};

export const useBalanceAmount = (address) => {
    const idoContract = useIdoContract(address);

    const fetchCapital = useCallback(async (account) => {
        if (!idoContract) {
            return 0;
        }
        const amount = await idoContract.methods.balance(account).call();

        return amount / 1e18;

    }, [idoContract]);

    return fetchCapital;
};


export const useIdoAdminActions = (contract, account) => {
    const idoContract = useIdoContract(contract, window.web3);
    const [, refreshWhitelist] = useRefreshWhilteListed(contract);
    const [, refreshIdo] = useRefreshIdo();

    const addToWhitelist = async (accounts) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.addAddressToWhitelist(accounts).estimateGas(args);
        const result = idoContract.methods.addAddressToWhitelist(accounts).send(args);

        return result.then(data => {
            refreshIdo(account);
            refreshWhitelist();
            return data;
        });;
    };

    const removeFromWhitelist = async (accounts) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0

        };
        await idoContract.methods.removeAddressesFromWhitelist(accounts).estimateGas(args);
        const result = idoContract.methods.removeAddressesFromWhitelist(accounts).send(args);
        return result.then(data => {
            refreshIdo(account);
            refreshWhitelist();
            return data;
        });;
    };


    const setOpenForAll = async (status) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.setOpenForAll(status).estimateGas(args);
        const result = idoContract.methods.setOpenForAll(status).send(args);
        return result.then(data => {
            refreshIdo(account);
            refreshWhitelist();
            return data;
        });
    };

    const fundPresale = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.fundPresale().estimateGas(args);
        const result = idoContract.methods.fundPresale().send(args);
        return result.then(data => {
            refreshIdo(account);
            return data;
        });
    };


    const finalizePresale = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.finalizePresale().estimateGas(args);
        const result = idoContract.methods.finalizePresale().send(args);
        return result.then(data => {
            refreshIdo(account);
            return data;
        });
    };

    return { addToWhitelist, removeFromWhitelist, setOpenForAll, fundPresale, finalizePresale };
};


export const useIdoActions = (address, account) => {
    const [, refreshIdo] = useRefreshIdo();
    const [, refreshInvestmentAmount] = useRefreshInvestmentAmount(address);
    const [, refreshBalance] = useRefreshUserBalanceAmount(address);
    const idoContract = useIdoContract(address, window.web3);

    const buy = async (amount, minAmount) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: window.web3.utils.toWei('' + amount)
        };

        await idoContract.methods.buyAndSwap(window.web3.utils.toWei('' + minAmount)).estimateGas(args);
        const result = idoContract.methods.buyAndSwap(window.web3.utils.toWei('' + minAmount)).send(args);

        return result.then(async result => {
            refreshIdo(address);
            refreshBalance(account);
            refreshInvestmentAmount(account);
            return result;
        });
    };

    const buyWithBusd = async (amount, minAmount) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: null
        };

        await idoContract.methods.buyWithBase(window.web3.utils.toWei('' + amount)).estimateGas(args);
        const result = idoContract.methods.buyWithBase(window.web3.utils.toWei('' + amount)).send(args);

        return result.then(async result => {
            refreshIdo(address);
            refreshBalance(account);
            refreshInvestmentAmount(account);
            return result;
        });
    };

    const refund = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await idoContract.methods.refund().estimateGas(args);
        const result = idoContract.methods.refund().send(args);

        return result.then(async result => {
            refreshIdo(address);
            refreshBalance(account);
            refreshInvestmentAmount(account);
            return result;
        });;
    };

    const emergencyWithdraw = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await idoContract.methods.emergencyWithdrawl().estimateGas(args);
        const result = idoContract.methods.emergencyWithdrawl().send(args);

        return result.then(async result => {
            refreshIdo(address);
            refreshBalance(account);
            refreshInvestmentAmount(account);
            return result;
        });;
    };

    return { buy, refund, buyWithBusd, emergencyWithdraw };

};




