import { createSlice } from '@reduxjs/toolkit';
import Vaults from "hpay/content/Vaults.json";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useVault as useFlashStakingVault } from '../web3/flash-staking';
import { useVault as useTimeLockVault } from '../web3/timelock-staking';
import { useVault as useSimpleStakingVault } from '../web3/simple-staking';

export const vaultListSlice = createSlice({
    name: 'vaults',
    initialState: {
        vaults: []
    },
    reducers: {
        setVaults: (state, action) => {
            state.vaults = action.payload;
        }
    },
});

export const { setVaults } = vaultListSlice.actions;

export const useVaults = () => {
    const [status, refresh] = useRefreshVaults();
    const selector = useSelector(state => ({
        vaults: state.vaults.vaults, status
    }));

    useEffect(() => {
        refresh();
    }, [refresh]);
    return selector;
};


export const useRefreshVaults = () => {
    const dispatch = useDispatch();
    const fetchFlashStaking = useFlashStakingVault();
    const fetchTimeLockVault = useTimeLockVault();
    const fetchSimpleVault = useSimpleStakingVault();

    return useAsyncFn(async () => {
        const vaults = [];
        for (const [key, value] of Object.entries(Vaults)) {
            if (!value.enabled) {
                continue;
            }

            if (value.mocked) {
                vaults.push({ ...value, location: key });
                continue;
            }

            let fn;
            if (value.type === "FLASH_STAKING") {
                fn = fetchFlashStaking;
            }

            if (value.type === 'TIME_LOCK') {
                fn = fetchTimeLockVault;
            }

            if (value.type === 'SIMPLE_STAKING') {
                fn = fetchSimpleVault;
            }

            const vault = await fn(value.address);
            console.log('here', vault);

            vaults.push({ ...vault.details, ...value, ...vault, location: key });
        }
        dispatch(setVaults(vaults));
    }, [dispatch, fetchFlashStaking, fetchTimeLockVault]);
};

export default vaultListSlice.reducer;