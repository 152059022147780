import Exchange from "hpay/contracts/Exchange.json";
import { useCallback } from "react";
import { Tokens, useContractWithProvider } from "./contracts/contracts";
import { useTokenPrice } from "./price-data";
import { useRefreshHPayBalance } from "../state/investor-info";


export const useExchangeContract = () => {
    const contract = useContractWithProvider(Exchange.address, Exchange.abi, window.web3);
    return contract;
};

export const useHpayExchangePrice = () => {

    const getPrice = useTokenPrice();
    
    const fetchPrice = useCallback(async (token1, token2) => {

        let path;
        if (token1.symbol !== Tokens.BNB.symbol && token2.symbol !== Tokens.BNB.symbol) {
            path = [token1, Tokens.BNB, token2];
        } else {
            path = [token1, token2];
        }

        let rate = await getPrice(path);
        // rate -= token1.symbol === 'HPAY' ? rate * 12 / 100 : rate * 10 / 100;
        return rate;
    }, [getPrice]);
    return fetchPrice;
};


export const useExchangeCapsFetch = () => {
    // const exchangeContract = useExchangeContract();
    const fetchCaps = useCallback(async () => {
        return { maxAmount: 0, minAmount: 50 };
    }, []);

    return fetchCaps;
};

export const useExchangeActions = (account) => {

    const exchangeContract = useExchangeContract();
    const [, refreshInvestorBalance] = useRefreshHPayBalance();
    const buy = async (amount, minAmount, coin) => {
        let result;
        if (coin === 'BNB') {
            result = handleBnbBuy(exchangeContract, amount, minAmount, account);
        } else {
            result = handleBusdBuy(exchangeContract, amount, minAmount, account);
        }

        return result.then(async result => {
            refreshInvestorBalance(account);
            return result;
        });
    };

    const sell = async (amount, minAmount, coin) => {

        let result;
        if (coin === 'BNB') {
            result = handleBnbSell(exchangeContract, amount, minAmount, account);
        } else {
            result = handleBusdSell(exchangeContract, amount, minAmount, account);
        }

        return result.then(async result => {
            refreshInvestorBalance(account);
            return result;
        });
    };

    const handleBnbBuy = async (exchangeContract, amount, minAmount, account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: window.web3.utils.toWei('' + amount)
        };
        await exchangeContract.methods.buyWithChainCoin(window.web3.utils.toWei('' + minAmount)).estimateGas(args);
        return exchangeContract.methods.buyWithChainCoin(window.web3.utils.toWei('' + minAmount)).send(args);
    };


    const handleBusdBuy = async (exchangeContract, amount, minAmount, account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await exchangeContract.methods.buy(window.web3.utils.toWei('' + amount), window.web3.utils.toWei('' + minAmount)).estimateGas(args);
        return exchangeContract.methods.buy(window.web3.utils.toWei('' + amount), window.web3.utils.toWei('' + minAmount)).send(args);
    };

    const handleBnbSell = async (exchangeContract, amount, minAmount, account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await exchangeContract.methods.sellTokensForChainCoin(window.web3.utils.toWei('' + amount), window.web3.utils.toWei('' + minAmount)).estimateGas(args);
        return exchangeContract.methods.sellTokensForChainCoin(window.web3.utils.toWei('' + amount), window.web3.utils.toWei('' + minAmount)).send(args);
    };


    const handleBusdSell = async (exchangeContract, amount, minAmount, account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        await exchangeContract.methods.sellTokens(window.web3.utils.toWei('' + amount), window.web3.utils.toWei('' + minAmount)).estimateGas(args);
        return exchangeContract.methods.sellTokens(window.web3.utils.toWei('' + amount), window.web3.utils.toWei('' + minAmount)).send(args);
    };


    return { buy, sell };

};
