import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useCurrentRound, useUserAmounts, useVault } from '../web3/flash-staking';
import { useAccount } from './account';
import Vaults from "hpay/content/Vaults.json";

export const flashStakingSlice = createSlice({
    name: 'flashStaking',
    initialState: {
        currentVault: null,
        currentRound: null,
        userLocked: 0,
        userUnlocked: 0,
        userEarned: 0,
        userPending: 0,
        userTotalEarned: 0,
    },
    reducers: {
        setCurrentVault: (state, action) => {
            state.currentVault = action.payload;
        },
        setCurrentRound: (state, action) => {
            state.currentRound = action.payload;
        },
        setUserLocked: (state, action) => {
            state.userLocked = action.payload;
        },
        setUserUnLocked: (state, action) => {
            state.userUnlocked = action.payload;
        },
        setUserEarned: (state, action) => {
            state.userEarned = action.payload;
        },
        setUserTotalEarned: (state, action) => {
            state.userTotalEarned = action.payload;
        },
        setUserPending: (state, action) => {
            state.userPending = action.payload;
        },
    },
});

export const { setCurrentVault, setCurrentRound, setUserUnLocked, setUserTotalEarned, setUserLocked, setUserEarned, setUserPending } = flashStakingSlice.actions;


export const useVaultData = (address) => {
    const fetchData = useVault();
    const [data, setData] = useState();

    useEffect(async () => {
        const data = await fetchData(address);
        setData(data);
    }, [address, setData]);

    return data;
};


export const useCurrentVault = (address) => {
    const [status, refresh] = useRefreshCurrentVault();

    const selector = useSelector(state => ({
        currentVault: state.flashStaking.currentVault, status
    }));

    useEffect(() => {
        refresh(address);
    }, [refresh, address]);

    return selector;
};



export const useRefreshCurrentVault = () => {
    const dispatch = useDispatch();
    const fetchData = useVault();

    return useAsyncFn(async (address) => {
        const data = await fetchData(address);
        const vault = Object.values(Vaults).find(item => item.address === address);

        if (!vault.enabled) {
            const time = Date.parse(vault.details.time);
            dispatch(setCurrentVault({ ...vault.details, time }));
        } else {
            dispatch(setCurrentVault({ ...vault.details, ...data }));
        }

    }, [dispatch, fetchData, setCurrentVault]);
};


export const useRefreshCurrentRound = () => {
    const dispatch = useDispatch();
    const fetchData = useCurrentRound();

    return useAsyncFn(async (vault) => {
        dispatch(setCurrentRound(await fetchData(vault)));
    }, [dispatch, fetchData]);
};


export const useUserVaultAmounts = (vault) => {
    const [status, refresh] = useRefreshUserAmount(vault);
    const account = useAccount();

    const selector = useSelector(state => ({
        userLocked: state.flashStaking.userLocked,
        userUnlocked: state.flashStaking.userUnlocked,
        userEarned: state.flashStaking.userEarned,
        userPending: state.flashStaking.userPending,
        userTotalEarned: state.flashStaking.userTotalEarned,
        status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, vault]);
    return selector;
};

export const useRefreshUserAmount = (vault) => {
    const dispatch = useDispatch();
    const fetchData = useUserAmounts(vault);

    return useAsyncFn(async (account) => {
        const [locked, unlocked, earned, pending, totalEarned] = await fetchData(account);

        dispatch(setUserLocked(locked));
        dispatch(setUserUnLocked(unlocked));
        dispatch(setUserEarned(earned));
        dispatch(setUserPending(pending));
        dispatch(setUserTotalEarned(totalEarned));

    }, [dispatch, fetchData]);
};

export default flashStakingSlice.reducer;