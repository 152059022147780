import { createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useIsAdmin, useUserAmounts, useVault } from '../web3/simple-staking';
import { useAccount } from './account';
import Vaults from "hpay/content/Vaults.json";

export const simpleStaking = createSlice({
    name: 'simpleStaking',
    initialState: {
        currentVault: null,
        userLocked: 0,
        userEarned: 0,
        userEarnedReflection: 0,
        isAdmin: false
    },
    reducers: {
        setCurrentVault: (state, action) => {
            state.currentVault = action.payload;
        },
        setUserLocked: (state, action) => {
            state.userLocked = action.payload;
        },

        setUserIsUnlocked: (state, action) => {
            state.isUnlocked = action.payload;
        },

        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },

        setUserEarnedReflection: (state, action) => {
            state.userEarnedReflection = action.payload;
        },

        setUserEarned: (state, action) => {
            state.userEarned = action.payload;
        },
    },
});

export const { setCurrentVault, setIsAdmin, setUserEarnedReflection, setUserLocked, setUserEarned, setUserIsUnlocked } = simpleStaking.actions;

export const useCurrentVault = (address) => {
    const [status, refresh] = useRefreshCurrentVault();

    const selector = useSelector(state => ({
        currentVault: state.simpleStaking.currentVault, status
    }));

    useEffect(() => {
        refresh(address);
    }, [refresh, address]);

    return selector;
};

export const useUserIsAdmin = (address) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserIsAdmin(address);

    const selector = useSelector(state => ({
        isAdmin: state.simpleStaking.isAdmin, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);
    return selector;
};


export const useRefreshCurrentVault = () => {
    const dispatch = useDispatch();
    const fetchData = useVault();

    return useAsyncFn(async (address) => {
        const data = await fetchData(address);
        const vault = Object.values(Vaults).find(item => item.address === address);
        if (!vault.enabled) {
            const time = Date.parse(vault.details.time);
            dispatch(setCurrentVault({ ...vault, ...vault.details, time }));
        } else {
            dispatch(setCurrentVault({ ...vault, ...vault.details, ...data }));
        }

    }, [dispatch, fetchData, setCurrentVault]);
};


export const useUserVaultAmounts = (vault) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserAmount(vault);

    const selector = useSelector(state => ({
        userLocked: state.simpleStaking.userLocked,
        userEarned: state.simpleStaking.userEarned,
        userEarnedReflection: state.simpleStaking.userEarnedReflection,
        status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);
    return selector;
};

export const useRefreshUserAmount = (vault) => {
    const dispatch = useDispatch();
    const fetchData = useUserAmounts(vault);

    return useAsyncFn(async (account) => {
        const [reward, balance, lock, unlocked, reflections] = await fetchData(account);

        dispatch(setUserLocked(balance));
        dispatch(setUserEarned(reward));
        dispatch(setUserEarnedReflection(reflections));
        dispatch(setUserIsUnlocked(unlocked));

    }, [dispatch, fetchData, vault]);
};

export const useRefreshUserIsAdmin = (vaultAddress) => {
    const dispatch = useDispatch();
    const fetchData = useIsAdmin(vaultAddress);

    return useAsyncFn(async (account) => {
        const status = await fetchData(account);
        dispatch(setIsAdmin(status));
    }, [dispatch, fetchData, setIsAdmin]);
};

export default simpleStaking.reducer;