import { createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useBalanceAmount, useIdo, useInvestedAmount, useIsAdmin, useIsWhiteListed } from '../web3/proxy-funded-ido';
import { useAccount } from './account';
import Idos from "hpay/content/Idos.json";

export const proxyFundedIdoSlice = createSlice({
    name: 'proxyFundedIdo',
    initialState: {
        currentIdo: null,
        investedAmount: 0,
        userBalance: 0,
        isWhitelisted: false,
        isAdmin: false
    },
    reducers: {

        setCurrentIdo: (state, action) => {
            state.currentIdo = action.payload;
        },

        setInvestedAmount: (state, action) => {
            state.investedAmount = action.payload;
        },
        setIsWhilteListed: (state, action) => {
            state.isWhitelisted = action.payload;
        },
        setUserBalance: (state, action) => {
            state.userBalance = action.payload;
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        }
    },
});

export const { setIdos, setCurrentIdo, setInvestedAmount, setUserBalance, setIsWhilteListed, setIsAdmin } = proxyFundedIdoSlice.actions;

export const useCurrentIdo = (address) => {
    const [status, refresh] = useRefreshIdo();

    const selector = useSelector(state => ({
        ido: state.proxyIdo.currentIdo, status
    }));

    useEffect(() => {
        refresh(address);
    }, [refresh, address]);
    return selector;
};


export const useUserIsAdmin = (address) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserIsAdmin(address);

    const selector = useSelector(state => ({
        isAdmin: state.proxyIdo.isAdmin, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);
    return selector;
};

export const useIsUserWhiteListed = (address) => {
    const account = useAccount();
    const [status, refresh] = useRefreshWhilteListed(address);

    const selector = useSelector(state => ({
        isWhitelisted: state.proxyIdo.isWhitelisted, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, address]);
    return selector;
};

export const useUserBalanaceAmount = (idoAddress) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserBalanceAmount(idoAddress);

    const selector = useSelector(state => ({
        userBalance: state.proxyIdo.userBalance, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, idoAddress]);
    return selector;
};

export const useUserInvestment = (idoAddress) => {
    const account = useAccount();
    const [status, refresh] = useRefreshInvestmentAmount(idoAddress);

    const selector = useSelector(state => ({
        investedAmount: state.proxyIdo.investedAmount, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, idoAddress]);
    return selector;
};

export const useRefreshUserBalanceAmount = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useBalanceAmount(idoAddress);

    return useAsyncFn(async (account) => {
        const amount = await fetchData(account);

        dispatch(setUserBalance(amount));
    }, [dispatch, fetchData, idoAddress]);
};

export const useRefreshUserIsAdmin = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useIsAdmin(idoAddress);

    return useAsyncFn(async (account) => {
        const status = await fetchData(account);
        dispatch(setIsAdmin(status));
    }, [dispatch, fetchData, idoAddress]);
};

export const useRefreshWhilteListed = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useIsWhiteListed(idoAddress);

    return useAsyncFn(async (account) => {
        const status = await fetchData(account);
        dispatch(setIsWhilteListed(status));
    }, [dispatch, fetchData, idoAddress]);
};

export const useRefreshInvestmentAmount = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useInvestedAmount(idoAddress);

    return useAsyncFn(async (account) => {
        const amount = await fetchData(account);
        dispatch(setInvestedAmount(amount));
    }, [dispatch, fetchData]);
};

export const useRefreshIdo = () => {
    const dispatch = useDispatch();
    const fetchIdo = useIdo();

    return useAsyncFn(async (address) => {
        const ido = Object.values(Idos).find(item => item.address === address);
        if (ido.mocked) {
            dispatch(setCurrentIdo({ ...ido }));
            return;
        }
        const cido = await fetchIdo(address);
        dispatch(setCurrentIdo({ ...ido, ...cido }));
    }, [dispatch, fetchIdo]);
};

export default proxyFundedIdoSlice.reducer;