import { useCallback, useEffect, useState } from "react";
import { RouterABI } from "./abi/routera.abi";
import { useContract } from "./contracts/contracts";
const bnb = {
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    decimals: 18,
};

const busd = {
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    decimals: 18,
};

export const useBnbPrice = () => {
    const [price, setPrice] = useState(0);
    const routerContract = useContract('0x10ED43C718714eb63d5aA57B78B54704E256024E', RouterABI);

    const fetchPrice = useCallback(async () => {

        if (routerContract) {
            const amountOut = await routerContract.methods.getAmountsOut((1 * 10 ** bnb.decimals).toString(), [bnb.address, busd.address]).call();
            const amountFormatted = amountOut[1] / 10 ** (busd.decimals);
            setPrice(amountFormatted);
        }

    }, [routerContract, setPrice]);

    useEffect(() => {
        fetchPrice();
    }, [fetchPrice]);

    return price;
};

export const useTokenPrice = () => {
    const routerContract = useContract('0x10ED43C718714eb63d5aA57B78B54704E256024E', RouterABI);
    const fetchPrice = useCallback(async (path) => {
        if (routerContract) {

            const amountOut = await routerContract.methods
                .getAmountsOut((1 * 10 ** path[0].decimals).toString(), path.map(item => item.address)).call().catch(err => {
                    console.log(err);
                });
            const result = (+amountOut[amountOut.length - 1]) / (10 ** (path[path.length - 1].decimals));
            return result;
        }

    }, [routerContract]);

    return fetchPrice;
};