import * as Web3 from 'web3/dist/web3.min.js';
import { ContractFactoryHof } from "./contracts/contract.factory";
import { BSC_URL, ContractAddresses, TARGET_NETWORK, __WEB_3__ } from './contracts/contracts';
import { injectedProviderInit } from "./providers/injected";
import { walletConnectProviderInit } from "./providers/walletconnect";
import HegeCoin from "hpay/contracts/HedgeCoin.json";


export let HedgeTokenContractInstance;


export const SupportedProviders = {
    WALLET_CONNECT: 'wallet_connect',
    INJECTED: 'injected'
};

const ProviderMap = {
    WALLET_CONNECT: walletConnectProviderInit,
    INJECTED: injectedProviderInit
};


export const disconnectWallet = async () => {
    if (window.web3 && window.web3.currentProvider.close) {
        window.web3.currentProvider.close().catch(console.log);
    }

    Object.values(window.connectListeners).forEach(callback => {
        callback(false);
    });
};

export const connectWallet = async (providerName) => {
    await disconnectWallet();

    await ProviderMap[providerName]();
    await initializeContracts(window.web3);
    Object.values(window.connectListeners).forEach(callback => {
        callback(true);
    });
};



const init = async () => {
    await disconnectWallet();

    if (window.ethereum) {

        window.injectedProvider.ethereum = window.ethereum;
        window.injectedProvider.web3 = window.web3;

        window.web3 = new Web3(window.injectedProvider.ethereum);

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (account) {
                Object.values(window.accountListeners).forEach(listener => listener(account[0]));
            });

            window.ethereum.on('chainChanged', function (networkId) {
                Object.values(window.networkChangeListeners).forEach(listener => listener('' + parseInt(networkId)));
            });

            window.ethereum.on('disconnect', () => {
                Object.values(window.connectListeners).forEach((callback) => {
                    callback(false);
                });
            });

        }
        if (window.web3) {
            initializeContracts(window.web3);
        }

    }
};

export const currentProviderIsMetamask = () => {
    if (typeof window !== 'undefined') {
        return window.web3.currentProvider.isMetaMask;
    }
};


export const getAccount = async () => {
    return window.web3 && window.web3.eth && window.web3.eth.getAccounts().then(accounts => {
        return accounts[0];
    });
};

export const isWalletUnlocked = async () => {
    if (window.ethereum && window.web3.eth) {
        const unlocked = await window.web3.eth.getAccounts().then(accounts => {
            return !!accounts && !!accounts[0];
        });
        return unlocked;
    }
    return false;
};

export const getNetworkId = async () => window.web3 && window.web3.eth && window.web3.eth.net && window.web3.eth.net.getId();

export const subscribeToNeworkChange = (id, callback) => {
    window.networkChangeListeners[id] = callback;
    getNetworkId().then(id => {
        callback('' + id);
    });

    setInterval(() => {
        getNetworkId().then(id => {
            callback('' + id);
        });
    }, 2000);

};

export const getBlockTime = async () => {
    return __WEB_3__.eth.getBlock("latest").then(data => data.timestamp * 1000);
};


export const subscribeToAccountChange = (id, callback) => {
    window.accountListeners[id] = callback;
    getAccount().then(account => {
        if (account) {
            callback(account);
        }
    });
};

export const subscribeToNeworkConnect = (id, callback) => {
    window.connectListeners[id] = callback;
};

export const addBscChain = () => {
    if (window.ethereum) {
        window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
                chainId: '0x' + parseInt(TARGET_NETWORK).toString(16),
                chainName: chainName(),
                nativeCurrency: {
                    name: 'Binance Smart Chain',
                    symbol: 'BNB',
                    decimals: 18
                },
                rpcUrls: [BSC_URL]
            }]
        }).catch(console.log);
    }
};


export const chainName = () => TARGET_NETWORK === '56' ? 'Binance Smart Chain' : 'Binance Smart Chain Testnet';

async function initializeContracts(web3) {
    if (!web3) {
        return;
    }

    ContractFactoryHof(web3).create(HegeCoin.abi, ContractAddresses.HPAY).then(instance => {
        HedgeTokenContractInstance = instance;
    });

}


if (typeof window !== 'undefined') {
    window.injectedProvider = {
        ethereum: null,
        web3: null
    };

    window.connectListeners = {};
    window.accountListeners = {};
    window.networkChangeListeners = {};
    init();
};
