import { createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useBalanceAmount, useIdo, useInvestedAmount, useIsAdmin, useIsWhiteListed } from '../web3/idos';
import { useIdo as useProxyFundedIdo } from '../web3/proxy-funded-ido';

import { useAccount } from './account';
import Idos from "hpay/content/Idos.json";

export const idosSlice = createSlice({
    name: 'idos',
    initialState: {
        idos: [],
        currentIdo: null,
        investedAmount: 0,
        userBalance: 0,
        isWhitelisted: true,
        isAdmin: false
    },
    reducers: {
        setIdos: (state, action) => {
            state.idos = action.payload;
        },
        setCurrentIdo: (state, action) => {
            state.currentIdo = action.payload;
        },
        setInvestedAmount: (state, action) => {
            state.investedAmount = action.payload;
        },
        setIsWhilteListed: (state, action) => {
            state.isWhitelisted = action.payload;
        },
        setUserBalance: (state, action) => {
            state.userBalance = action.payload;
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        }
    },
});

export const { setIdos, setCurrentIdo, setInvestedAmount, setUserBalance, setIsWhilteListed, setIsAdmin } = idosSlice.actions;

export const useIdos = () => {
    const [status, refresh] = useRefreshIdos();
    const selector = useSelector(state => ({
        idos: state.idos.idos, status
    }));

    useEffect(() => {
        refresh();
    }, [refresh]);
    return selector;
};

export const useCurrentIdo = (address) => {
    const [status, refresh] = useRefreshIdo();

    const selector = useSelector(state => ({
        ido: state.idos.currentIdo, status
    }));

    useEffect(() => {
        refresh(address);
    }, [refresh, address]);
    return selector;
};


export const useUserIsAdmin = (address) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserIsAdmin(address);

    const selector = useSelector(state => ({
        isAdmin: state.idos.isAdmin, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);
    return selector;
};

export const useIsUserWhiteListed = (address) => {
    const account = useAccount();
    const [status, refresh] = useRefreshWhilteListed(address);

    const selector = useSelector(state => ({
        isWhitelisted: state.idos.isWhitelisted, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, address]);
    return selector;
};

export const useUserBalanaceAmount = (idoAddress) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserBalanceAmount(idoAddress);

    const selector = useSelector(state => ({
        userBalance: state.idos.userBalance, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, idoAddress]);
    return selector;
};

export const useUserInvestment = (idoAddress) => {
    const account = useAccount();
    const [status, refresh] = useRefreshInvestmentAmount(idoAddress);

    const selector = useSelector(state => ({
        investedAmount: state.idos.investedAmount, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, idoAddress]);
    return selector;
};

export const useRefreshUserBalanceAmount = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useBalanceAmount(idoAddress);

    return useAsyncFn(async (account) => {
        const amount = await fetchData(account);

        dispatch(setUserBalance(amount));
    }, [dispatch, fetchData, idoAddress]);
};

export const useRefreshUserIsAdmin = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useIsAdmin(idoAddress);

    return useAsyncFn(async (account) => {
        const status = await fetchData(account);
        dispatch(setIsAdmin(status));
    }, [dispatch, fetchData, idoAddress]);
};

export const useRefreshWhilteListed = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useIsWhiteListed(idoAddress);

    return useAsyncFn(async (account) => {
        const status = await fetchData(account);
        dispatch(setIsWhilteListed(status));
    }, [dispatch, fetchData, idoAddress]);
};

export const useRefreshInvestmentAmount = (idoAddress) => {
    const dispatch = useDispatch();
    const fetchData = useInvestedAmount(idoAddress);

    return useAsyncFn(async (account) => {
        const amount = await fetchData(account);
        dispatch(setInvestedAmount(amount));
    }, [dispatch, fetchData]);
};

export const useRefreshIdos = () => {
    const dispatch = useDispatch();
    const fetchIdo = useIdo();
    const fectchProxy = useProxyFundedIdo();

    return useAsyncFn(async () => {
        const idos = [];
        for (const [key, value] of Object.entries(Idos)) {
            if (!value.enabled) {
                continue;
            }

            if (value.mocked) {
                idos.push({ ...value, location: key });
                continue;
            }

            if (value.type === "BASIC") {
                const ido = await fetchIdo(value.address);
                idos.push({ ...value, ...ido, location: key });
            } else {
                const ido = await fectchProxy(value.address);
                idos.push({ ...value, ...ido, location: key });
            }
        }

        dispatch(setIdos(idos));
    }, [dispatch, fetchIdo]);
};

export const useRefreshIdo = () => {
    const dispatch = useDispatch();
    const fetchIdo = useIdo();

    return useAsyncFn(async (address) => {
        let ido = Object.values(Idos).find(item => item.address === address);
        if (ido.mocked) {
            dispatch(setCurrentIdo({ ...ido }));
            return;
        }

        const chainIdo = await fetchIdo(address);
        dispatch(setCurrentIdo({ ...ido, ...chainIdo }));
    }, [dispatch, fetchIdo]);
};

export default idosSlice.reducer;