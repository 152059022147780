import { createSlice } from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useFundWorker } from '../web3/fund';
import { useRefreshHPayBalance } from './investor-info';

export const bountyInfoSlice = createSlice({
    name: 'bountyInfo',
    initialState: {
        bountyValue: 0,
        bonus: null
    },
    reducers: {
        setBountyValue: (state, action) => {
            state.bountyValue = action.payload;
        },
        setBonus: (state, action) => {
            state.bonus = action.payload;
        }
    },
});

export const { setBountyValue, setBonus } = bountyInfoSlice.actions;

export const useBountyValue = () => {
    const [status, refresh] = useRefreshBountyValue();
    const selector = useSelector(state =>  ({
        bountyValue: state.bountyInfo.bountyValue, status
    }));

    useEffect(() => {
        refresh();
    }, [refresh]);

    return selector;
};

// This is an hook action which constructs and wraps an async action to produces `state` for us.
export const useRefreshBountyValue = () => {
    const dispatch = useDispatch();
    const { bountyRewards } = useFundWorker();

    return useAsyncFn(async () => {
        const { bounty } = await bountyRewards();
        dispatch(setBountyValue(bounty));
    }, [dispatch, bountyRewards]);
};


export const useBountyBonus = () => {
    const [status, refresh] = useRefreshBountyBonus();
    const selector = useSelector(state =>  ({
        bonus: state.bountyInfo.bonus, status
    }));

    useEffect(() => {
        refresh();
    }, [refresh]);

    return selector;
};


export const useRefreshBountyBonus = () => {
    const dispatch = useDispatch();
    const { bountyBonus } = useFundWorker();

    return useAsyncFn(async () => {
        const bonus = await bountyBonus();
        dispatch(setBonus(bonus));
    }, [dispatch, bountyBonus]);
};


export const useBountyActions = () => {
    const { stashProfits } = useFundWorker();
    const [, refreshBounty] = useRefreshBountyValue();
    const [, refreshBlanace] = useRefreshHPayBalance();
    const [, refreshBonus] = useRefreshBountyBonus();


    const claimBounty = useCallback(async (account) => {
        return stashProfits(account).then(async (result) => {
            await refreshBounty();
            await refreshBlanace(account)
            await refreshBonus()
            return result;
        });
    }, [stashProfits, refreshBounty]);


    return { claimBounty };
};




export default bountyInfoSlice.reducer;