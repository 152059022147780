import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use'; import { useIsAdmin, useUserAmounts, useUserWhitelisted, useVault } from '../web3/timelock-staking';
import { useAccount } from './account';
import Vaults from "hpay/content/Vaults.json";

export const timeLockStakingSlice = createSlice({
    name: 'timeLockStaking',
    initialState: {
        currentVault: null,
        userLocked: 0,
        userEarned: 0,
        userEarnedReflection: 0,
        userUnlockTime: 0,
        isUnlocked: false,
        isWhitelisted: true,
        isAdmin: false
    },
    reducers: {
        setCurrentVault: (state, action) => {
            state.currentVault = action.payload;
        },
        setUserLocked: (state, action) => {
            state.userLocked = action.payload;
        },

        setUserIsUnlocked: (state, action) => {
            state.isUnlocked = action.payload;
        },

        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },

        setUserEarnedReflection: (state, action) => {
            state.userEarnedReflection = action.payload;
        },

        setIsWhitelisted: (state, action) => {
            console.log(action);
            state.isWhitelisted = action.payload;
        },

        setUserEarned: (state, action) => {
            state.userEarned = action.payload;
        },
        setUserUnlockTime: (state, action) => {
            state.userUnlockTime = action.payload;
        },
    },
});

export const { setCurrentVault, setIsWhitelisted, setIsAdmin, setUserEarnedReflection, setUserLocked, setUserEarned, setUserUnlockTime, setUserIsUnlocked } = timeLockStakingSlice.actions;

export const useCurrentVault = (address) => {
    const [status, refresh] = useRefreshCurrentVault();

    const selector = useSelector(state => ({
        currentVault: state.timeLockStaking.currentVault, status
    }));

    useEffect(() => {
        refresh(address);
    }, [refresh, address]);

    return selector;
};

export const useUserIsAdmin = (address) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserIsAdmin(address);

    const selector = useSelector(state => ({
        isAdmin: state.timeLockStaking.isAdmin, status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account]);
    return selector;
};


export const useVaultData = (address) => {
    const fetchData = useVault();
    const [data, setData] = useState();

    useEffect(async () => {
        const data = await fetchData(address);
        setData(data);
    }, [address, setData]);

    return data;
};


export const useRefreshCurrentVault = () => {
    const dispatch = useDispatch();
    const fetchData = useVault();

    return useAsyncFn(async (address) => {
        const data = await fetchData(address);
        const vault = Object.values(Vaults).find(item => item.address === address);
        if (!vault.enabled) {
            const time = Date.parse(vault.details.time);
            dispatch(setCurrentVault({ ...vault, ...vault.details, time }));
        } else {
            dispatch(setCurrentVault({ ...vault, ...vault.details, ...data }));
        }

    }, [dispatch, fetchData, setCurrentVault]);
};

export const useUserIsWhitelisted = (vault) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserIsWhitelisted(vault);

    const selector = useSelector(state => ({
        isWhitelisted: state.timeLockStaking.isWhitelisted,
        status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, vault]);
    return selector;
};

export const useRefreshUserIsWhitelisted = (vault) => {
    const dispatch = useDispatch();
    const fetchData = useUserWhitelisted(vault);

    return useAsyncFn(async (account) => {
        const result = await fetchData(account);
        dispatch(setIsWhitelisted(result));
    }, [dispatch, fetchData, vault]);
};

export const useUserVaultAmounts = (vault) => {
    const account = useAccount();
    const [status, refresh] = useRefreshUserAmount(vault);

    const selector = useSelector(state => ({
        userLocked: state.timeLockStaking.userLocked,
        userEarned: state.timeLockStaking.userEarned,
        userUnlockTime: state.timeLockStaking.userUnlockTime,
        isUnlocked: state.timeLockStaking.isUnlocked,
        userEarnedReflection: state.timeLockStaking.userEarnedReflection,
        status
    }));

    useEffect(() => {
        refresh(account);
    }, [refresh, account, vault]);
    return selector;
};

export const useRefreshUserAmount = (vault) => {
    const dispatch = useDispatch();
    const fetchData = useUserAmounts(vault);

    return useAsyncFn(async (account) => {
        const [reward, balance, lock, unlocked, reflections] = await fetchData(account);
        dispatch(setUserLocked(balance));
        dispatch(setUserEarned(reward));
        dispatch(setUserUnlockTime(lock));
        dispatch(setUserEarnedReflection(reflections));
        dispatch(setUserIsUnlocked(unlocked));

    }, [dispatch, fetchData, vault]);
};



export const useRefreshUserIsAdmin = (vaultAddress) => {
    const dispatch = useDispatch();
    const fetchData = useIsAdmin(vaultAddress);

    return useAsyncFn(async (account) => {
        const status = await fetchData(account);
        dispatch(setIsAdmin(status));
    }, [dispatch, fetchData, setIsAdmin]);
};

export default timeLockStakingSlice.reducer;